import * as React from 'react';

interface IPartnersProps {
}

const Statistics: React.FunctionComponent<IPartnersProps> = (props) => {
  return (
<section className="section bg-primary text-white">
        <div className="container">
          <div className="row gap-y">

            <div className="col-md-4">
              <div className="row">
                <div className="col-6">
                  <h4 className="lead-7 text-right" data-provide="countup" data-from="0" data-to="792489"></h4>
                </div>

                <div className="col-6">
                  <p className="small text-uppercase ls-2 mb-2">Print Jobs</p>
                  <p><i className="icon-briefcase lead-4 mb-0"></i></p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-6">
                  <h4 className="lead-7 text-right" data-provide="countup" data-from="0" data-to="120862"></h4>
                </div>

                <div className="col-6">
                  <p className="small text-uppercase ls-2 mb-2">End Users</p>
                  <p><i className="icon-profile-male lead-4 mb-0"></i></p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-6">
                  <h4 className="lead-7 text-right" >99.9%</h4>
                </div>

                <div className="col-6">
                  <p className="small text-uppercase ls-2 mb-2">Uptime</p>
                  <p><i className="icon-happy lead-4 mb-0"></i></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
  );
};

export default Statistics;






