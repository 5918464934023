import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image';

// declare var Calendly: any;

import dash1 from "../../../static/dash-1-sm.png";

export interface ILiveDemoFormProps {}

export default function LiveDemoForm(props: ILiveDemoFormProps) {
  const data = useStaticQuery(graphql`
  query {
    dash1: file(relativePath: { eq: "dash-1.png" }) {
      ...fluidImage
    }
  }
`);

  const [formData, setFormData] = React.useState<{ [key: string]: string }>({
    hide_event_type_details: "1"
  });

  const onClickSchedule = () => {
    const qs = Object.keys(formData)
      .filter(k => ["email", "name"].includes(k))
      .map(
        key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
      )
      .join("&");

    const qs2 = [
      `a1=1${encodeURIComponent(formData.phone.replace(/\D/g, ""))}`,
      `a2=${encodeURIComponent(formData.companyName)}`
    ].join("&");

    (window as any).Calendly.showPopupWidget(
      `https://calendly.com/inkcloud/30min?${qs}&${qs2}`
    );
    return false;
  };

  return (
    <section className="section text-white bg-primary" id="live-demo">
      <div className="container">
      <form onSubmit={onClickSchedule} method="POST" data-form="mailer">
        <div className="row align-items-center">
          <div className="col-lg-5 mr-auto text-center">
            <h3 className="heading-alt fw-300">Book a 30-min live demo</h3>
            <br />
            <p>
              Request a personal live demo and we'll answer any questions you
              have about the platform
            </p>
            <br />

            
              <div className="alert alert-success d-on-success">
                We received your request and will contact you back soon.
              </div>

            
          </div>
          <div className="col-lg-5 mx-auto">
          {/* <Img fluid={data.dash1.childImageSharp.fluid} data-aos="fade-up" /> */}
            {/* <img src={dash1} alt="..." data-aos="fade-up" /> */}

            <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </div>

              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="company_name"
                  placeholder="Company name"
                  onChange={e =>
                    setFormData({ ...formData, companyName: e.target.value })
                  }
                />
              </div>

              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Work email"
                  onChange={e =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>

              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  onChange={e =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
              </div>

              <button className="btn btn-success btn-block" type="submit">
                Schedule Demo
              </button>
              <div id="calendly-root"></div>

          </div>
        </div>
        </form>
      </div>
    </section>
  );
}

