import * as React from "react";
import { useStaticQuery, graphql } from 'gatsby';

export interface IPricing3Props {
}

export const Pricing3 = (props: IPricing3Props) => {
  const data = useStaticQuery(graphql`
  query SubheaderQuery{
    contentfulBlockPricing(blockName: {}) {
      id
      blockName
      title
      lead { lead }
      package1Name
      package2Name
      package3Name
      package1Price
      package2Price
      package3Price
      package1Features
      package2Features
      package3Features
    }  
  }
`);

const { contentfulBlockPricing: { 
  title, 
  lead,
  package1Name,
  package2Name,
  package3Name,
  package1Price,
  package2Price,
  package3Price,
  package1Features,
  package2Features,
  package3Features,
}} = data;

const handleDemoClick = () => {
  (window as any).Calendly.showPopupWidget(
    'https://calendly.com/inkcloud/30min'
  );
}

  return (
<section className="section bg-gray" style={{paddingTop: '4rem', paddingBottom: '4rem'}}>
        <div className="container">
          <header className="section-header">
            <small>Pricing</small>
            <h2>{title}</h2>
            <hr />
            <p className="lead">{lead?.lead}</p>
          </header>


          <div className="row gap-y">

            <div className="col-lg-4">
              <div className="pricing-3">
                <h6 className="plan-name text-primary">{package1Name}</h6>
                <h2 className="price">{package1Price}</h2>
                <ul>
                  {package1Features?.map((f: string, idx: number) => f.slice(0,1) === '_' ? <li key={idx}><del>{f.slice(1)}</del></li> : <li key={idx}>{f}</li>)}
                </ul>
                {/* <br /> */}
                {/* <a className="btn btn-primary btn-round w-200" href="#" onClick={handleDemoClick}>Book Demo</a> */}
              </div>
            </div>


            <div className="col-lg-4">
              <div className="pricing-3">
                <h6 className="plan-name text-primary">{package2Name}</h6>
                <h2 className="price">{package2Price}</h2>
                <ul>
                {package2Features?.map((f: any, idx: number) => f.slice(0,1) === '_' ? <li key={idx}><del>{f.slice(1)}</del></li> : <li key={idx}>{f}</li>)}
                </ul>
                {/* <br /> */}
                {/* <a className="btn btn-primary btn-round w-200" href="#" onClick={handleDemoClick}>Book Demo</a> */}
              </div>
            </div>


            <div className="col-lg-4">
              <div className="pricing-3">
                <h6 className="plan-name text-primary">{package3Name}</h6>
                <h2 className="price">{package3Price}</h2>
                <ul>
                {package3Features?.map((f: any, idx: number) => f.slice(0,1) === '_' ? <li key={idx}><del>{f.slice(1)}</del></li> : <li key={idx}>{f}</li>)}
                </ul>
                {/* <br /> */}
                {/* <a className="btn btn-primary btn-round w-200" href="#" onClick={handleDemoClick}>Book Demo</a> */}
              </div>
            </div>

          </div>

        </div>
      </section>

    );
};
