import * as React from 'react';

interface IAppProps {
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <section className="section" id="contact">
      <div className="container">
        <h2 className="text-center">Contact Us</h2>
        <div className="row gap-y mt-8">
          <form className="col-lg-6" action="/" name="contact" netlify="true" method="POST" data-form="mailer">
            <div className="alert alert-success d-on-success">We received your message and will contact you back soon.</div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <input className="form-control form-control-lg" type="text" name="firstname" placeholder="First Name" />
              </div>

              <div className="form-group col-md-6">
                <input className="form-control form-control-lg" type="text" name="lastname" placeholder="Last Name" />
              </div>

              <div className="form-group col-md-6">
                <input className="form-control form-control-lg" type="email" name="email" placeholder="Email" />
              </div>

              <div className="form-group col-md-6">
                <input className="form-control form-control-lg" type="text" name="phone" placeholder="Phone" />
              </div>
            </div>

            <div className="form-group">
              <textarea className="form-control form-control-lg" rows={4} placeholder="What do you have in mind?" name="message"></textarea>
            </div>
            <div style={{ display: 'none' }}>
              <input type="text" name="enter-it" />
            </div>
            <input type="hidden" name="form-name" value="contact" />
            <button className="btn btn-lg btn-primary" type="submit">Send message</button>

          </form>

          <div className="col-lg-5 ml-auto text-center text-lg-left">
            <hr className="d-lg-none" />
            <h3>Our Offices</h3>
            <h5>Americas</h5>
            <p>Ink Cloud <br /> 2425 Olympic Boulevard<br />Suite 4000-W PMB #529<br/> Santa Monica, CA 90404 USA </p>
            <h5>Asia</h5>
            <p>Ink Cloud <br />HM Tower, 15th Floor, West Geonzon Street, Cebu I.T. Park, <br />Lahug, Cebu City, 6000 Philippines</p>
            {/* <p>+1 (321) 654 9870<br />+1 (987) 123 6548</p> */}
            <p>info@inkcloud9.com</p>
            <div className="fw-400">Follow Us</div>
            <div className="social social-sm social-inline">
              <a className="social-twitter" href="#"><i className="fa fa-twitter"></i></a>
              <a className="social-facebook" href="#"><i className="fa fa-facebook"></i></a>
              {/* <a className="social-instagram" href="#"><i className="fa fa-instagram"></i></a> */}
              {/* <a className="social-dribbble" href="#"><i className="fa fa-dribbble"></i></a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;
