import * as React from "react";

export interface IFeature11Props {
  title: string;
  subtitle: string;
  lead: any;
  image: any;
  icon: string;
  buttonText: string;
  buttonUrl: string;
  points: [];
}

export const Feature11 = (props: IFeature11Props) => {
  const { title, subtitle, lead, buttonText, buttonUrl, icon, image, points } = props;

  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        {points?.slice(0, 3).map((p: any, idx: number) => {
          return (
            <React.Fragment key={idx}>
              { idx > 0 ? <hr className="my-8" /> : null}
              <div className="row gap-y align-items-center">
                <div className="col-md-6 ml-auto">
                  <h4>{p.tItle}</h4>
                  <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
                </div>

                <div className={`col-md-5 ${idx===1 ? 'order-md-first' : ''}`}>
                  <img className="rounded shadow-2" src={p.image?.fluid?.src} alt={p.image?.description} />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </section>

  );

  return (
    <section className="section pb-0 overflow-hidden">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row gap-y text-center">

          {points?.slice(0, 3).map((p: any, idx: number) => {
            let delay;

            if (idx === 0) {
              delay = 300;
            }

            if (idx === 2) {
              delay = 600;
            }

            return (
              <div key={idx} className="col-md-4 d-flex flex-column">
                <div className="mb-7">
                  <p className={`text-info lead-7 ${idx === 0 ? 'mb-0' : ''}`}>{p.subtitle}</p>
                  <p>{p.tItle}</p>
                </div>
                <div className={`${idx === 0 || idx === 2 ? 'px-5' : ''} mt-auto`}>
                  <img className="shadow-4 opacity-80" src={p.image?.file?.url} alt={p.image?.description} data-aos="slide-up" data-aos-delay={delay} />
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </section>
  )
};
