import * as React from "react";
import { chunk } from 'underscore';

export interface IFeature15Props {
  title: string;
  text: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
  list: string[];
}

export const Feature15 = (props: IFeature15Props) => {
  const { title, text, buttonText, buttonUrl, image, list = [] } = props;

  const chunkedList = chunk(list ?? [], 2);

  return (
    <section className="section overflow-hidden">
      <div className="container">
        <div className="row gap-y align-items-center">

          <div className="col-lg-6 mx-auto">
            <h2>{title}</h2>
            <p className="lead mb-7">{text?.text}</p>
            <div className="row">
              {chunkedList.map((cl: any, idx: number) => (
                <div key={`row${idx}`} className="col-md-6">
                  {cl.map((c: any, idx2: number) => (
                    <p key={`list${idx2}`}><i className="fa fa-caret-right text-lightest mr-2"></i> {c}</p>
                  ))}
                </div>
              ))}
            </div>

            <p className="mt-6">
              <a className="text-uppercase fw-600 ls-2 small-2" href={buttonUrl}>{buttonText} <i className="ti-arrow-right fs-10 ml-1"></i></a>
            </p>
          </div>

          <div className="col-lg-5 align-self-center order-md-first">
            <img src={image?.file?.url} alt={image?.description} />
          </div>

        </div>
      </div>
    </section>);
};
