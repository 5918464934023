import * as React from "react";

export interface IService2Props {
  title: string;
  subtitle: string;
  lead: any;
  points: [];
}

export const Service2 = (props: IService2Props) => {
  const { title, subtitle, lead, points } = props;
  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row">
          {points?.map((p: any, idx: number) => (
            <React.Fragment key={idx}>
              <div className="col-md-6 col-xl-4 ml-auto">
                <h5><a href="#">{p.tItle}</a></h5>
                <p className="small opacity-50">{p.subtitle}</p>
              </div>

              <div className="col-md-6">
                <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
                <br /><br />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
};
