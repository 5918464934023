import * as BlockHeader from '../components/blocks/header';
import * as BlockFeature from '../components/blocks/feature';
import * as BlockFeatureText from '../components/blocks/feature-text';
import * as BlockService from '../components/blocks/service';
import * as BlockContent from '../components/blocks/content';
import * as BlockStatic from '../components/blocks/static';
import * as BlockPricing from '../components/blocks/pricing';

export const blockHeaderResolver: any = (blockName: string) => {
  const map: any = {
    BlockHeader1: BlockHeader.Header1,
    BlockHeader3: BlockHeader.Header3,
    BlockHeader4: BlockHeader.Header4,
    BlockHeader5: BlockHeader.Header5,
    BlockHeader6: BlockHeader.Header6,
    BlockHeader7: BlockHeader.Header7,
    BlockHeader8: BlockHeader.Header8,
    BlockHeader9: BlockHeader.Header9,
    StaticBlockHeader1: BlockStatic.Header1,
  }

  if (!map[blockName]) {
    throw Error(`${blockName} is not a valid blockName`);
  }
  
  return map[blockName];
}

export const blockResolve = (block: any) => {
  const {__typename, blockName} = block;

  if (!blockName) {
    console.log(__typename,'is missing a blockname, returning empty element')
    return () => null;
  }
   
  const map: any = {
    ContentfulBlockFeatureA: {
      BlockFeature1: BlockFeature.Feature1,
      BlockFeature3: BlockFeature.Feature3,
      BlockFeature4: BlockFeature.Feature4,
      BlockFeature6: BlockFeature.Feature6,
      BlockFeature11: BlockFeature.Feature11,
    },
    ContentfulBlockFeatureC: {
      BlockFeature2: BlockFeature.Feature2,
      BlockFeature10: BlockFeature.Feature10,      
      BlockFeature14: BlockFeature.Feature14,
      BlockFeature15: BlockFeature.Feature15,
      BlockFeature16: BlockFeature.Feature16,
    },
    ContentfulBlockFeatureTextA: {
      BlockFeatureText1: BlockFeatureText.FeatureText1,
      BlockFeatureText3: BlockFeatureText.FeatureText3,
      BlockFeatureText4: BlockFeatureText.FeatureText4
    },
    ContentfulBlockService: {
      BlockService1: BlockService.Service1,
      BlockService2: BlockService.Service2,
      BlockService3: BlockService.Service3,
      BlockService4: BlockService.Service4,
      BlockService5: BlockService.Service5,
    },
    ContentfulBlockContent: {
      BlockContent1: BlockContent.Content1,
      BlockContent3: BlockContent.Content3,
      BlockContent4: BlockContent.Content4,
    },
    ContentfulBlockPricing: {
      BlockPricing3: BlockPricing.Pricing3,
    },    
    ContentfulStaticBlock: {
      StaticBlockHeader1: BlockStatic.Header1,
      StaticBlockIntegrations: BlockStatic.Integrations,
      StaticBlockLiveDemo: BlockStatic.LiveDemoForm,
      StaticBlockContactUs: BlockStatic.ContactUs,
      StaticBlockStatistics: BlockStatic.Statistics,
      StaticBlockTeam: BlockStatic.Team,
      StaticBlockFaq3: BlockStatic.Faq3,
    }
  }
  
  if (!map[__typename]) {
    throw Error(`${__typename} is not a valid blockType`);
  }
  
  if (!map[__typename][blockName]) {
    throw Error(`${__typename} does not have a valid name of "${blockName}" `);
  }
  
  return map[__typename][blockName];
}
