import * as React from "react";

export interface IHeader3Props {
  title: string;
  subtitle: string;
}

export const Header3: React.FunctionComponent<IHeader3Props> = props => {
  const { title, subtitle } = props;
  return (
    <header className="header">
      <div className="container">
        <h1 className="display-4">{title}</h1>
        <p className="lead-2 mt-6">{subtitle}</p>
      </div>
    </header>    
  );
};
