import * as React from "react";

export interface IContent4Props {
  title: string;
  lead: any;
  points: string[];
}

export const Content4 = (props: IContent4Props) => {
  const { title, lead, points } = props;
  return (
    <section id="section-mission" className="section">
      <div className="container">
        <div className="row gap-y align-items-center">
          <div className="col-md-7 mx-auto">
            <h2>{title}</h2>
            <p className="lead">{lead?.lead}</p>

            <br />
            {points?.map((p: any, idx: number) => (
              <p key={idx}>
                <i className="ti-check text-success mr-2"></i>
                <span> {p.tItle}</span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
