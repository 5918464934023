import * as React from "react";

export interface IFeatureText3Props {
  title: string;
  subtitle: string;
  lead: any;
  points: [];
}

export const FeatureText3 = (props: IFeatureText3Props) => {
  const { title, subtitle, lead, points } = props;
  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row gap-y text-center">

          {points?.map((p: any, idx: number) => (
            <div key={idx} className="col-md-6 col-xl-4 feature-1">
              <p className="feature-icon"><img src={p.image?.fluid?.src} alt={p.image?.description} /></p>
              <h5>{p.tItle}</h5><br/>
              <div className="text-muted" dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
            </div>
          ))}
        </div>

      </div>
    </section>

  );
};
