import * as React from 'react'
import Helmet from 'react-helmet'
import { withPrefix, StaticQuery, graphql } from 'gatsby'
import 'modern-normalize'
import '../../styles/normalize'

import '../../../saas-theme/src/assets/scss/page.scss';
import 'html5-device-mockups/dist/device-mockups.min.css';

import Navbar from '../Landing/Navbar'
import LayoutRoot from '../LayoutRoot'
import { Footer10 } from '../blocks/footer';

interface GeneralLayoutProps {
  navBarColor?: 'navbar-dark' | 'navbar-light'
  navBarStickyColor?: 'navbar-stick-dark' | 'navbar-stick-light'
  htmlTitle: string;
  metaDescription: string;
  metaTags: string;
}

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 800) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const fluidLogo = graphql`
fragment fluidLogo on File {
  childImageSharp {
    fluid(maxWidth: 400) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;


interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      siteUrl: string;
      image: string;
      twitterUsername: string;
    }
  }
}

const GeneralLayout: React.FC<GeneralLayoutProps> = ({ 
  children, 
  navBarColor = 'navbar-dark',
  navBarStickyColor = 'navbar-stick-dark',
  htmlTitle,
  metaDescription,
  metaTags,
}) => (
  <StaticQuery
    query={graphql`
      query GeneralLayoutQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            twitterUsername
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      return (
      <LayoutRoot>
        <Helmet
          htmlAttributes={{ lang : 'en' }}
          title={htmlTitle}
        >
        <link rel="preconnect" href="https://assets.calendly.com"></link>
        <script src={withPrefix('page.min.js')} type="text/javascript" />
        <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet"></link>        
        <meta name="description" content={metaDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:url" content="inkcloud9.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />

        <meta property="og:image" content={data.site.siteMetadata.image}  />
        <meta name="og:image" content={data.site.siteMetadata.image}  />

        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta name="twitter:description" content={data.site.siteMetadata.description} />
        <meta property="twitter:image" content={data.site.siteMetadata.image} />
          {/* <link rel="preload" href="styles.css" as="style" onLoad="this.onload=null;this.rel='stylesheet'"></link>
          <noscript><link rel="stylesheet" href="styles.css"></noscript>          */}
        </Helmet>
        <Navbar color={navBarColor} stickColor={navBarStickyColor} />
        
        {children}
        <Footer10
          button1={{label: 'Book Demo', to: '#demo' }}
          button2={{label: 'Support', to: '/support' }}
          copyright="© 2022 Ink Cloud. All rights reserved."
          leftHeader="Ink Cloud"
          leftText="Ink Cloud is a leading software solutions provider for the print industry and the next generation of Print Tech. Print better, faster, and smarter through our all-in-one cloud based platform."
          rightHeader="Contact Us"
          rightText="Click the links below to book a live product demo or to contact our support team"
          links={[
            { 
              header: 'Product',
              links: [
                {label: 'Overview', to: '/product/#overview' },
                {label: 'Features', to: '/product/#features' },
                {label: 'Benefits', to: '/product/#benefits' },
                {label: 'Integrations', to: '/product/#integrations' },
                {label: 'Pricing', to: '/product/#pricing' }
              ]
            },
            { 
              header: 'Solutions',
              links: [
                {label: 'Commercial Printers', to: '/solutions' },
                {label: 'Trade Printers', to: '/solutions' },
                {label: 'Print Brokers', to: '/solutions' },
                {label: 'Fulfillment', to: '/solutions' },
                {label: 'Corporate', to: '/solutions' },
                {label: 'Marketing Agencies', to: '/solutions' },
              ]
            }

          ]}
        />
        <img style={{height: 1}} src="https://ct.capterra.com/capterra_tracker.gif?vid=2200012&vkey=2db51851309af1bc5f03b427d77c7831" />
      </LayoutRoot>
    );
  }}
  />
)

export default GeneralLayout



