import * as React from 'react';

interface IHeader6Props {
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonUrl: string;
  backgroundImage: any;
}

export const Header6: React.FunctionComponent<IHeader6Props> = (props) => {
  const { title, subtitle, buttonUrl, buttonLabel, backgroundImage } = props;

  return (
    <header className="header mb-9" style={{ backgroundImage: `url(${backgroundImage?.file?.url})`}}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="fw-200 mb-6">{title}</h1>
            <p className="lead-2">{subtitle}</p>

            <hr className="w-50px ml-0"  />

            <a className="text-muted" href={buttonUrl}>{buttonLabel}</a>
          </div>
        </div>
      </div>
    </header>    
  );
};
