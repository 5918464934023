import * as React from "react";
import Img from 'gatsby-image';

import stripe from '../../../images/integrations/stripe.svg';
import authorizenet from '../../../images/integrations/authorizenet.svg';

import xero from '../../../images/integrations/xero.svg';
import quickbooks from '../../../images/integrations/quickbooks-1.svg';

import mailchimp from '../../../images/integrations/mailchimp.svg';
import klaviyo from '../../../images/integrations/klaviyo.svg';
import googleAnalytics from '../../../images/integrations/google-analytics-2.svg';
import justuno from '../../../images/integrations/justuno-wordmark.svg';

import usps from '../../../images/integrations/usps.svg';
import ups from '../../../images/integrations/ups.svg';
import fedex from '../../../images/integrations/fedex.svg';
import canadaPost from '../../../images/integrations/canada-post.svg';
import australiaPost from '../../../images/integrations/australia-post.svg';

import slack from '../../../images/integrations/slack.svg';


const svgImgStyle = { display: 'block', margin: 'auto', marginTop: '8%', minHeight: 75, maxHeight: 75, maxWidth: '60%' };

interface IIntegrationsProps {
  // image1: any;
  // image2: any;
  // image3:any;
}

const items = [
  {
    title: 'Stripe',
    subTitle: 'Payments',
    groups: 'payments',
    image: stripe,
  },
  {
    title: 'Authorize.net',
    subTitle: 'Payments',
    groups: 'payments',
    image: authorizenet,
  },

  {
    title: 'Xero',
    subTitle: 'Accounting',
    groups: 'accounting',
    image: xero,
  },
  {
    title: 'Quickbooks',
    subTitle: 'Accounting',
    groups: 'accounting',
    image: quickbooks,
  },
  {
    title: 'Mailchimp',
    subTitle: 'Marketing',
    groups: 'marketing',
    image: mailchimp,
  },
  {
    title: 'Klaviyo',
    subTitle: 'Marketing',
    groups: 'marketing',
    image: klaviyo,
  },
  {
    title: 'Google Analytics',
    subTitle: 'Marketing',
    groups: 'marketing',
    image: googleAnalytics,
  },
  {
    title: 'Justuno',
    subTitle: 'Marketing',
    groups: 'marketing',
    image: justuno,
  },
  {
    title: 'FedEx',
    subTitle: 'Shipping',
    groups: 'shipping',
    image: fedex,
  },
  {
    title: 'UPS',
    subTitle: 'Shipping',
    groups: 'shipping',
    image: ups,
  },
  {
    title: 'USPS',
    subTitle: 'Shipping',
    groups: 'shipping',
    image: usps,
  },    
  {
    title: 'Canada Post',
    subTitle: 'Shipping',
    groups: 'shipping',
    image: canadaPost,
  },      
  {
    title: 'Australia Post',
    subTitle: 'Shipping',
    groups: 'shipping',
    image: australiaPost,
  },        
  {
    title: 'Slack',
    subTitle: 'Productivity',
    groups: 'productivity',
    image: slack,
  },        
]


const Integrations: React.FunctionComponent<IIntegrationsProps> = props => {
  return (
<section className="section overflow-hidden bg-gray">
        <div className="container">
          <header className="section-header">
            {/* <small>Feature</small> */}
            <h2>Integrations</h2>
            <hr />
            <p className="lead">Take advantage of our built-in integrations</p>
          </header>


          <div data-provide="shuffle">
            <ul className="nav nav-center nav-bold nav-uppercase nav-pills mb-7" data-shuffle="filter">
              <li className="nav-item">
                <a className="nav-link active" href="#" data-shuffle="button">All</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-shuffle="button" data-group="shipping">Shipping</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-shuffle="button" data-group="payments">Payments</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-shuffle="button" data-group="accounting">Accounting</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-shuffle="button" data-group="marketing">Marketing</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-shuffle="button" data-group="productivity">Productivity</a>
              </li>
            </ul>


            <div className="row gap-y gap-2" data-shuffle="list">

            {items.map((i: any, idx: number) => (
              <div key={idx} className="col-6 col-lg-3" data-shuffle="item" data-groups={i.groups} >
                <a className="card shadow-1 hover-shadow-6" href="#" data-toggle="modal" data-target="#modal-portfolio" >
                  <img className="card-img-top" alt={i.title} src={i.image} style={svgImgStyle} />
                  <div className="card-body">
                    <h6 className="mb-0">{i.title}</h6>
                    <small className="small-5 text-lightest text-uppercase ls-2">{i.subTitle}</small>
                  </div>
                </a>
              </div>              
            ))}

            </div>
          </div>
        </div>
      </section>    
  );
};

export default Integrations;
