import * as React from "react";
import Img from "gatsby-image";

export interface IContent1Props {
  subtitle: string;
  title: string;
  lead: string;
  image: any;
}

export const Content1 = (props: IContent1Props) => {
  const { subtitle, title, lead, image } = props;
  return (
    <section className="section">
      <div className="container">
        <div className="row gap-y align-items-center">
          <div className="col-md-6 text-center text-md-right">
            <p className="small-2 text-uppercase text-lightest fw-500 ls-1">
              {subtitle}
            </p>
            <h3 className="fw-500">{title}</h3>
            <br />
            <p>{lead}</p>
          </div>

          <div className="col-md-6">
            <img className="rounded-md ml-md-4" src={image?.file?.url} alt={image?.description} />
          </div>
        </div>
      </div>
    </section>
  );
};
