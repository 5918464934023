import * as React from "react";

export interface IFeature14Props {
  title: string;
  text: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
}

export const Feature14 = (props: IFeature14Props) => {
  const { title, text, buttonText, buttonUrl, image } = props;

  return (
    <section className="section bg-gray overflow-hidden">
      <div className="container-fluid">
        <div className="row gap-y align-items-center">

          <div className="col-md-4 mx-auto text-center">
            <img src={image?.file?.url} alt={image?.description} data-aos="fade-right" />
          </div>


          <div className="col-md-4 mx-auto">
            <h3>{title}</h3>
            <br />
            <p>{text.text}</p>
            <a href={buttonUrl}>{buttonText} <i className="ti-angle-right fs-10 ml-1"></i></a>
          </div>

        </div>
      </div>
    </section>
  );
};
