import * as React from "react";

export interface IService1Props {
  title: string;
  subtitle: string;
  lead: any;
  points: [];
}

export const Service1 = (props: IService1Props) => {
  const { title, subtitle, lead, points } = props;

  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row">
          {points?.map((p: any, idx: number) => (
            <div key={idx} className="col-md-6 col-xl-4 ml-lg-auto">
              <h6 className="bt-2 border-secondary py-5"><a href="#">{p.tItle}</a></h6>
              <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
              <br /><br />
            </div>
          ))}
        </div>

      </div>
    </section>
  )
};
