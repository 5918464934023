import * as React from "react";

export interface IHeader9Props {
  title: string;
  subtitle: string;
}

export const Header9: React.FunctionComponent<IHeader9Props> = (props) => {
  const { title, subtitle } = props;
  return (
    <header className="header">
        <div className="container">
          <div className="row align-items-center h-100">

            <div className="col-md-7 mx-auto">
              <h1>{title}</h1>
              <p className="lead-3">{subtitle}</p>
            </div>

          </div>
        </div>
      </header>
  );
};
