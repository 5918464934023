import * as React from "react";

export interface IFeature10Props {
  title: string;
  subtitle: string;
  text: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
  backgroundColor: any;
  list: string[];
}

export const Feature10 = (props: IFeature10Props) => {
  const { title,  text, buttonText, buttonUrl, image, list } = props;

  return (

    <section className="section overflow-hidden bg-gray">
    <div className="container-fluid pl-lg-0">
      <div className="row gap-y align-items-center">

        <div className="col-lg-6 mx-auto">
          <h2>{title}</h2>
          <p className="lead mb-7">{text}</p>

          {list.map((c: any, idx: number) => (
            <p key={idx}>
              <i className="ti-check text-success mr-2"></i>
              <span className="fs-14">{c}</span>
            </p>
          ))}
        <p className="mt-7">
          <a className="text-uppercase fw-600 ls-2 small-2" href={buttonUrl}>{buttonText} <i className="ti-arrow-right fs-10 ml-1"></i></a>
        </p>          
        </div>

        <div className="col-lg-5 align-self-center order-md-first">
          <img className="shadow-3" src={image?.file?.url} alt={image?.description} data-aos="slide-right" data-aos-duration="1500" />
        </div>

      </div>
    </div>
  </section>

  )
};

