import * as React from "react";

export interface IFeature6Props {
  title: string;
  subtitle: string;
  lead: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
  points: [];
  backgroundColor: string;
}

export const Feature6 = (props: IFeature6Props) => {
  const { title, lead, image, points } = props;

  return (
    <section className="section bg-gray pb-0 overflow-hidden">
      <div className="container">
        <div className="row align-items-center">

          <div className="col-md-5 pb-8">
            <h2>{title}</h2>
            <p className="lead">{lead?.lead}</p>

            {points?.slice(0, 2).map((p: any, idx: number) => (
              <React.Fragment key={idx}>
                <br />
                <div className="media">
                  <div className="lead-5 text-danger lh-2 mr-5"><i className={p.icon}></i></div>
                  <div className="media-body">
                    <h6>{p.tItle}</h6>
                    <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
                  </div>
                </div>

              </React.Fragment>
            ))}
          </div>

          <div className="col-md-5 text-center mx-auto">
            <img src={image?.file?.url} alt={image?.description} data-aos="fade-up" data-aos-delay="200" />
          </div>
        </div>
      </div>
    </section>

  );
};






