import * as React from "react";

export interface IHeader4Props {
  title: string;
  subtitle: string;
  backgroundColor: string;
}

export const Header4: React.FunctionComponent<IHeader4Props> = props => {
  const { title, subtitle, backgroundColor } = props;
  return (
    <header className="header text-white" style={{ backgroundColor: backgroundColor }}>
      <div className="container text-center">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h1>{title}</h1>
            <p className="lead-2 opacity-90 mt-6">{subtitle}</p>
          </div>
        </div>
      </div>
    </header>
  );
};


















