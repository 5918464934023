import * as React from "react";

export interface IFeature1Props {
  title: string;
  subtitle: string;
  lead: any;
  image: any;
  icon: string;
  buttonText: string;
  buttonUrl: string;
  points: [];
}

export const Feature1 = (props: IFeature1Props) => {
  const { title, subtitle, lead, buttonText, buttonUrl, icon, image, points } = props;

  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row gap-y">
          <div className="col-md-8 mx-auto mb-7">
            <img src={image?.file?.url} alt={image?.description} data-aos="fade-up" data-aos-duration="2000" />
          </div>

          <div className="w-100"></div>

          {points?.map((p: any, idx: number) => (
            <div key={idx} className="col-md-6 col-xl-4">
              <div className="media">
                <div className="lead-6 line-height-1 text-dark w-70px"><i className={`text-primary ${p.icon}`}></i></div>
                <div className="media-body">
                  <h5>{p.tItle}</h5>
                  <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <br /><br />
            <a className="btn btn-lg btn-success" href={buttonUrl}>{buttonText}</a>
          </div>
        </div>
      </div>
    </section>
  )
};
