import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby"

interface IAppProps {
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const data = useStaticQuery(graphql`
  query HeaderQuery {
    team:  allContentfulTeammate {
      edges {
        node {
          id 
          firstName
          lastName
          text { text }
          photo {
            file { url }
          }
        }
      }
    }
  }
`);

  const team = data.team.edges.map(e => e.node);

  return (
    <section className="section">
    <div className="container">
      <header className="section-header">
        <small>Team</small>
        <h2>Our Team</h2>
        <hr />
        <p className="lead">Our team is comprised of veterans in the print, software and start-up space with over 30 years of combined experience.</p>
      </header>


      <div className="row gap-y">
        {team?.map((teammate: any, idx: number) => {

          return (
            <div key={idx} className="col-md-6 col-lg-3 team-1">
            <a href="#">
              <img src={teammate.photo?.file?.url} alt={teammate.photo?.description} />
            </a>
            <h6>{teammate.firstName} {teammate.lastName} <small>{teammate.title}</small></h6>
            <p>{teammate.text?.text}</p>
          </div>  
          )
        })}

      </div>

    </div>
  </section>  );
};

export default App;














