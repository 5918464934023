import * as React from "react";

export interface IFeature16Props {
  title: string;
  subtitle: string;
  text: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
  backgroundColor: any;
}

export const Feature16 = (props: IFeature16Props) => {
  const { title, subtitle, text, buttonText, buttonUrl, image, backgroundColor } = props;

  return (
<section className="section text-white" style={{backgroundColor: backgroundColor}}>
        <div className="container-fluid">
          <div className="row gap-y align-items-center">

            <div className="col-md-5 ml-auto">
              <h3>{title}</h3>
              <p className="lead">{subtitle}</p>
              <p>{text.text}</p>
              <a href={buttonUrl}>{buttonText} <i className="ti-angle-right fs-10 ml-1"></i></a>
            </div>

            <div className="col-md-4 mx-auto text-center">
              <img src={image?.file?.url} alt={image?.description}  />
            </div>

          </div>
        </div>
      </section>  );
};

