import * as React from 'react';

interface IHeader1Props {
  title: string;
  buttonLabel: string;
  buttonUrl: string;
}

export const Header1: React.FunctionComponent<IHeader1Props> = (props) => {
  const { title, buttonLabel, buttonUrl } = props;
  return (
    <header className="header">
      <div className="container text-center">
        <h1 className="display-4 mb-7">{title}</h1>
        <a className="btn btn-lg btn-round btn-primary" href={buttonUrl}>{buttonLabel}</a>
      </div>
    </header>  
  );
};
