import * as React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby';

interface IPartnersProps {}

const Faq3: React.FunctionComponent<IPartnersProps> = (props) => {
  const data = useStaticQuery(graphql`
  query Faq3Query {
    faqs:  allContentfulFaqEntries(sort: {fields: priority})  {
      edges {
        node {
          id 
          question {question}
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`);

const faqs = data.faqs.edges.map(e => e.node);
console.log(faqs);


return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>FAQ</small>
          <h2>Frequently Asked Questions</h2>
          <hr />
          <p>
            Got a question? We've got answers. If you have some other questions,
            see our support center.
          </p>
        </header>

        <div className="row">
          <div className="col-md-8 mx-auto">
            <div
              className="accordion accordion-connected accordion-arrow-right"
              id="accordion-1"
            >

              {faqs.map((f: any, idx: number) => (
                <div className="card" key={idx}>
                <h5 className="card-title">
                  <a data-toggle="collapse" href={`#collapse-1-${idx}`}>
                    {f.question?.question}
                  </a>
                </h5>

                <div
                  id={`collapse-1-${idx}`}
                  className="collapse"
                  data-parent="#accordion-1"
                >
                  <div className="card-body" dangerouslySetInnerHTML={{__html: f.answer?.childMarkdownRemark?.html}}>
                  </div>
                </div>
              </div>

              ))}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq3;
