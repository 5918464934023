import * as React from "react";
import { Link } from 'gatsby';

interface FooterLink {
  to: string;
  label: string;
}

interface Column {
  header: string;
  links: FooterLink[];
}

export interface IFooter10Props {
  leftHeader: string;
  leftText: string;
  rightHeader: string;
  rightText: string;
  button1: FooterLink;
  button2: FooterLink;
  links: Column[]
  copyright: string;
}

export const Footer10 = (props: IFooter10Props) => {
  const {
    button1, button2,
    leftHeader, rightHeader, leftText, rightText,
    links, copyright
  } = props;

  return (
    <footer className="footer text-white bg-dark py-7" style={{borderTop: 0}}>
      <div className="container">
        <div className="row gap-y">
          <div className="col-xl-4 order-md-last">
            <h6 className="mb-4"><strong>{rightHeader}</strong></h6>
            <p>{rightText}</p>

            <Link className="btn btn-success mr-2" to="#">Demo</Link>
            <Link className="btn btn-secondary" to="/support">Support</Link>
          </div>
          {links.map((column: Column, idx: number) => (
            <div key={idx} className="col-6 col-md-4 col-xl-2">
              <h6 className="mb-4"><strong>{column.header}</strong></h6>
              <div className="nav flex-column">
                {column.links.map((link, idx2) => (
                  <Link className="text-primary" key={idx2} to={link.to}>{link.label}</Link>
                ))}
              </div>
            </div>
          ))}

          <div className="col-xl-4 order-md-first">
            <h6 className="mb-4"><strong>{leftHeader}</strong></h6>
            <p>{leftText}</p>
            <small className="opacity-70">{copyright}</small>
          </div>
        </div>
      </div>
    </footer>);
};


