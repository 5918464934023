import * as React from "react";

export interface IHeader8Props {
  backgroundImage: any;
  title: string;
  text: string;
  overlay?: number;
}

export const Header8: React.FunctionComponent<IHeader8Props> = (props) => {
  const { backgroundImage, title, text, overlay = 6 } = props;
  return (
    <header
      className="header text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      data-overlay={overlay}
    >
      <div className="container text-center">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h1>{title}</h1>
            <p className="lead-2 opacity-90 mt-6">
              {text}
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};
