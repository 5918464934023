import * as React from "react";

export interface IService3Props {
  title: string;
  subtitle: string;
  lead: any;
  points: [];
}

export const Service3 = (props: IService3Props) => {
  const { title, subtitle, lead, points } = props;

  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row gap-y">
          {points?.map((p: any, idx: number) => (
            <div key={idx} className="col-md-6 col-xl-3">
              <div className="card card-body shadow-3 text-center bg-success text-white">
                <p className="my-5"><i className={`${p.icon} lead-6`}></i></p>
                <h5 className="mb-5 fw-500"><a href="#">{p.tItle}</a></h5>
                <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
};
