import * as React from 'react';

interface IHeader7Props {
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonUrl: string;
  backgroundColor: string;
}

export const Header7: React.FunctionComponent<IHeader7Props> = (props) => {
  const { title, subtitle, backgroundColor, buttonLabel, buttonUrl,  } = props;
  return (

    <header className="header text-white bg-gray1 pt-10 pb-9" style={{backgroundColor: backgroundColor}}>
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">

              <h1>{title}</h1>
              <p className="lead-2"><i className="fa fa-map-marker mr-2"></i> {subtitle}</p>

              <hr className="w-50px" />

              <a className="btn btn-xl btn-round btn-light" href={buttonUrl}>{buttonLabel}</a>

            </div>
          </div>

        </div>
      </header>  );
};















