import * as React from "react";

export interface IService4Props {
  title: string;
  subtitle: string;
  lead: any;
  points: [];
}

export const Service4 = (props: IService4Props) => {
  const { title, subtitle, lead, points } = props;

  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>


        <div className="row gap-y">
          {points?.map((p: any, idx: number) => (
            <div key={idx} className="col-sm-6 col-lg-4">
              <p><i className={`${p.icon} text-primary lead-6`}></i></p>
              <hr className="mt-0 ml-0 w-10 border-primary" />
              <h6>{p.tItle}</h6>
              <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
            </div>
          ))}

        </div>

      </div>
    </section>
  );
};
