import * as React from "react";

export interface IFeature2Props {
  title: string;
  text: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
}

export const Feature2 = (props: IFeature2Props) => {
  const { title, text, buttonText, buttonUrl, image } = props;

  return (
    <section className="section bg-gray overflow-hidden">
      <div className="container">
        <div className="row">

          <div className="col-md-6 align-self-center text-center text-md-left">
            <h2>{title}</h2><br />
            <p>{text.text}</p>
            <br />
            <a className="btn btn-lg btn-round btn-primary shadow-3" href={buttonUrl}>{buttonText}</a>
          </div>

          <div className="col-md-5 mx-auto text-center mt-8 mt-md-0">
            <img src={image?.file?.url} alt={image?.description} data-aos="fade-up" />
          </div>

        </div>
      </div>
    </section>
  );
};
