import * as React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";

export interface IAppProps {}

export default function App(props: IAppProps) {
  const data = useStaticQuery(graphql`
  query {
    image: file(relativePath: { eq: "dash-1.png" }) {
      ...fluidImage
    }
  }
`);

  return (
    <header
      id="home"
      className="header"
      style={{
        // backgroundImage: "linear-gradient(150deg, #fdfbfb 0%, #eee 100%)"
        backgroundImage: "linear-gradient(-180deg, #FAFCFE 0%, #45ADEA 72%)"
      }}
    >
      <div className="container">
        <div className="row align-items-center h-100">
          <div className="col-lg-5">
            <h1 className="display-4">
              {/* <strong>Ink Cloud</strong> */}
              <br />
              {/* Print Smarter */}
              <strong>Next Gen of Print Tech</strong>
            </h1>
            <p className="lead mt-5" style={{ color: 'black' }}>
              Print better, faster, and smarter through our all-in-one cloud based platform

              {/* Cloud based platform offering end to end solutions for the print
              industry */}
            </p>

            <hr className="w-10 ml-0 my-4" />

            <p className="gap-xy">

              <Link className="btn btn-lg btn-round btn-success mw-200" to={'#live-demo'}>Book a Demo</Link>
              {/* <a className="btn btn-lg btn-round btn-outline-success mw-200" href="#section-features">Features</a> */}
            </p>
            <hr className="w-10 ml-0 my-3" />
          </div>

          <div className="col-lg-6 ml-auto">
            {data.image &&
              <div className="">
                <div className="device" data-device="Macbook" data-orientation="portrait" data-color="white">
                  <div className="screen">
                    <Img
                      fluid={data.image.childImageSharp.fluid}
                      alt="InkCloud Print Dashboard"
                      data-aos="fade-up"
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </header>
  );
}
