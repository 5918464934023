import * as React from "react";

export interface IContent3Props {
  title: string;
  subtitle: string;
  lead: any;
  subtitle2: string;
  lead2: any;
}

export const Content3 = (props: IContent3Props) => {
  const { title, subtitle, lead, subtitle2, lead2 } = props;
  return (
    <section className="section">
        <div className="container">
          <div className="row gap-y">

            <div className="col-md-3 mr-md-auto">
              <p className="lead-4 text-right">{title}</p>
            </div>

            <div className="col-md-4">
              <h6>{subtitle}</h6>
              <p>{lead?.lead}</p>
            </div>

            <div className="col-md-4">
              <h6>{subtitle2}</h6>
              <p>{lead2?.lead2}</p>
            </div>

          </div>
        </div>
      </section>
  );
};
