import * as React from "react";

export interface IFeatureText4Props {
  preTitle: string;
  title: string;
  text: string;
  image: any;
  imageAlt: string;
}

export const FeatureText4 = (props: IFeatureText4Props) => {
  const { preTitle, title, text, image, imageAlt } = props;
  return (
    <section className="section bg-gray">
    <div className="container">

      <div className="row gap-y">

        <div className="col-md-4 feature-2">
          <p className="feature-icon"><i className="icon-presentation"></i></p>
          <h6>Present</h6>
          <p>Option as can distributors. And to suppliers, given a copy the horrible arrange yes, we had hundreds leave was more.</p>
        </div>

        <div className="col-md-4 feature-2">
          <p className="feature-icon"><i className="icon-scope"></i></p>
          <h6>Impression</h6>
          <p>Option as can distributors. And to suppliers, given a copy the horrible arrange yes, we had hundreds leave was more.</p>
        </div>

        <div className="col-md-4 feature-2">
          <p className="feature-icon"><i className="icon-target"></i></p>
          <h6>Conversion</h6>
          <p>Option as can distributors. And to suppliers, given a copy the horrible arrange yes, we had hundreds leave was more.</p>
        </div>

      </div>


      <hr className="w-50px ml-0" />

      <p><a href="#">Discover more features <i className="ti-angle-right fs-9 ml-1"></i></a></p>


    </div>
  </section>
  );
};
