import * as React from "react";

export interface IService5Props {
  title: string;
  subtitle: string;
  lead: any;
  points: [];
}

export const Service5 = (props: IService5Props) => {
  const { title, subtitle, lead, points } = props;

  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>{subtitle}</small>
          <h2>{title}</h2>
          <hr />
          <p className="lead">{lead?.lead}</p>
        </header>

        <div className="row text-center">
          <div className="col-md-10 mx-auto">
            <div className="row gap-y">
              {points?.map((p: any, idx: number) => (
                <div key={idx} className="col-md-6">
                  <p><i className={`${p.icon} text-lighter lead-6 mb-5`}></i></p>
                  <h5>{p.tItle}</h5>
                  <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
