import * as React from "react";

export interface IFeature4Props {
  title: string;
  subtitle: string;
  lead: any;
  image: any;
  buttonText: string;
  buttonUrl: string;
  points: [];
  backgroundColor: string;
}

export const Feature4 = (props: IFeature4Props) => {
  const { title, lead, image, points, backgroundColor } = props;

  return (
    <section className="section text-white pb-0 overflow-hidden" style={{ backgroundColor }}>
      <div className="container">

        <div className="row">
          <div className="col-md-6 pb-8">
            <h2>{title}</h2>
            <p>{lead?.lead}</p>

            <hr className="w-50px ml-0" />

            <div className="row">
              {points?.slice(0, 2).map((p: any, idx: number) => (
                <div key={idx} className="col-6">
                  <p className="lead-5"><i className={p.icon}></i></p>
                  <p className="text-uppercase fw-600 mb-0">{p.tItle}</p>
                  <div dangerouslySetInnerHTML={{__html: p.text?.childMarkdownRemark?.html}}></div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-6 align-self-end text-center">
            <img src={image?.file?.url} alt={image?.description} data-aos="fade-up" />
          </div>
        </div>

      </div>
    </section>
  )
};






